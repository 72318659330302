:root {
  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-grey-dark-1: #333;
  --color-grey-dark-2: #415161;
  --color-grey-dark-3: #777;
  --color-grey-dark-4: #999; }

  

* {
  padding: 0;
  margin: 0; }

*::after, *::before, * {
  box-sizing: inherit; }

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-size: 100%; }

@font-face {
  font-family: myFirstFont;
  src: url("./images/fonts/Big_Caslon_Medium.ttf"); }

@font-face {
  font-family: mySecondFont;
  src: url("./images/fonts/Garogier_Regular.ttf"); }

@font-face {
  font-family: myCoverFont;
  src: url("./images/fonts/Alphakind.ttf"); }

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  font-size: 16px; }
  @media only screen and (max-width: 900px) {
    body {
      font-size: 16px; } }

.header {
  width: 100%;
  height: 100vh; }

.main {
  background-color: var(--color-grey-light-1);
  min-height: 100vh; }

.divider {
  position: absolute;
  right: 0;
  top: -200px;
  width: 40%;
  height: 150vh;
  border-radius: 100% 0 0 100%;
  background-color: #52b9c0; }

.slider_img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 2.375rem;
  left: 11.25%;
  width: 40rem;
  height: 30rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  @media only screen and (max-width: 900px) {
    .slider_img {
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20rem;
      height: 15rem; } }
  .slider_img h1 {
    position: absolute;
    top: 75%;
    font-size: 2rem;
    padding-left: .5rem;
    padding-right: .5rem;
    text-align: center;
    color: #a555bf;
    width: 100%; }
    @media only screen and (max-width: 900px) {
      .slider_img h1 {
        font-size: 1rem; } }
  .slider_img.one {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.001), rgba(0, 0, 0, 0.9)), url("./images/slider1.jpg"); }
  .slider_img.two {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.001), rgba(0, 0, 0, 0.9)), url("./images/slider3.jpg"); }
  .slider_img.three {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9)), url("./images/slider4.jpg"); }
  .slider_img.four {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), url("./images/slider8.jpg"); }
  .slider_img.five {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9)), url("./images/slider9.jpg"); }

.slider_book {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 2.375rem;
  right: 3.75%;
  width: 40rem;
  height: 32.9375rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center; }

  .slider_gift {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 2.375rem;
  right: 3.75%;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center; }

  @media only screen and (max-width: 900px) {
    .slider_book {
      top: 60%;
      right: 50%;
      transform: translate(50%, -50%); } }

.book-container {
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 37.5rem; }

@keyframes initAnimation {
  0% {
    transform: rotateY(0deg); }
  100% {
    transform: rotateY(-25deg); } }

.book {
  width: 20.8125rem;
  height: 31.25rem;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateY(-25deg);
  transition: 1s ease;
  animation: 1s ease 0s 1 initAnimation; }
  @media only screen and (max-width: 900px) {
    .book {
      width: 99px;
      height: 153px; } }

.book:hover {
  transform: rotateY(0deg); }

.book > :first-child {
  position: absolute;
  top: 0;
  left: 0;
  background-color: red;
  width: 20.8125rem;
  height: 31.25rem;
  transform: translateZ(50px);
  background-color: #01060f;
  border-radius: 0 5px 5px 0;
  box-shadow: 5px 5px 20px #666; }
  @media only screen and (max-width: 900px) {
    .book > :first-child {
      width: 99px;
      height: 153px;
      transform: translateZ(25px); } }

.book::before {
  position: absolute;
  content: ' ';
  background-color: blue;
  left: 0;
  top: 0.4275rem;
  width: 6.125rem;
  height: 30.375rem;
  transform: translateX(276px) rotateY(90deg);
  background: linear-gradient(90deg, #fff 0%, #f9f9f9 5%, #fff 10%, #f9f9f9 15%, #fff 20%, #f9f9f9 25%, #fff 30%, #f9f9f9 35%, #fff 40%, #f9f9f9 45%, #fff 50%, #f9f9f9 55%, #fff 60%, #f9f9f9 65%, #fff 70%, #f9f9f9 75%, #fff 80%, #f9f9f9 85%, #fff 90%, #f9f9f9 95%, #fff 100%); }
  @media only screen and (max-width: 900px) {
    .book::before {
      left: 0;
      top: 3px;
      width: 48px;
      height: 147px;
      transform: translateX(71px) rotateY(90deg); } }

.book::after {
  position: absolute;
  top: 0;
  left: 0;
  content: ' ';
  width: 20.8125rem;
  height: 31.25rem;
  transform: translateZ(-50px);
  background-color: #01060f;
  border-radius: 0 5px 5px 0;
  box-shadow: -10px 0 50px 10px #666; }
  @media only screen and (max-width: 900px) {
    .book::after {
      width: 99px;
      height: 153px;
      transform: translateZ(-25px); } }

.slider_wrapper {
  position: relative;
  height: 100vh;
  padding-top: 4.375rem; }

.gift_slider_wrapper {
  position: relative;
  height: 100px;
  padding-top: 4.375rem;
}

section {
  width: 100%;
  min-height: 100vh; }
  section.avatar_section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ff00cc;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #333399, #ff00cc), url("./images/avatar-bg1.jpg");
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #333399, #ff00cc), url("./images/avatar-bg1.jpg");
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #FF0088; }
    @media only screen and (max-width: 900px) {
      section.avatar_section {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding-bottom: 2rem; } }
    section.avatar_section .section__heading {
      color: var(--color-grey-light-2);
      font-weight: 500; }
    section.avatar_section .bton {
      top: 82%;
      background-color: #f08;
      border: none;
      color: var(--color-grey-light-1);
      padding-right: 2.5rem;
      padding-left: 2.5rem;
      font-weight: 600; }
      section.avatar_section .bton:hover {
        background-color: #e64980; }
      @media only screen and (max-width: 900px) {
        section.avatar_section .bton {
          align-self: center;
          position: inherit;
          left: 40%; } }
  section.order_steps {
    background-color: var(--color-grey-light-1);
    background-attachment: fixed;
    background-position: center center;
    position: relative; }
    section.order_steps .bton {
      top: 80%;
      margin-top: 1rem;
      padding-right: 2.5rem;
      padding-left: 2.5rem;
      font-weight: 500; }
      section.order_steps .bton:hover {
        background-color: #e64980; }
      @media only screen and (max-width: 900px) {
        section.order_steps .bton {
          width: 80%;
          align-self: center;
          position: inherit;
          margin-top: 40px;
          margin-bottom: 40px; } }

.avatar_wrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: rgba(255, 255, 255, 0.25);
  /* From https://css.glass */
  background: rgba(216, 121, 231, 0.21);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(216, 121, 231, 0.3); }
  .avatar_wrapper .avatar--m {
    margin-right: -15rem; }

.cover_design {
  display: flex;
  justify-content: space-around; }
  .cover_design .instruction_list {
    padding: 0 4rem;
    width: 25%; }
    @media only screen and (max-width: 900px) {
      .cover_design .instruction_list {
        width: 80%;
        padding: 3rem 6rem; } }
  @media only screen and (max-width: 900px) {
    .cover_design {
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding-top: 40px; } }

.cover_controls {
  width: 396px; }
  .cover_controls .shadow {
    background-color: var(--color-grey-light-3); }
  .cover_controls .d-flex {
    font-size: 14.4px;
    font-weight: 400; }
  .cover_controls .form-control {
    font-size: 14.4px;
    font-weight: 400; }
  .cover_controls h2.control_header {
    font-weight: 300; }

.cover_paper {
  width: 396px;
  height: 612px;
  position: 'relative';
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat; }
  .cover_paper > * {
    position: absolute; }
  .cover_paper p {
    position: absolute; }

@media print {
  html, body {
    width: 396px;
    height: 612px; }
  @page {
    size: 4.125in 6.375in !important; } }

.nav_margin, .nav-margin {
  margin-top: 70px; }

.products_screen {
  padding-top: 2rem; }
  .products_screen .row .col-xl-3 {
    padding: 0 1rem; }

.form-label {
  margin-bottom: 0; }

textarea.form-control {
  resize: none; }

.controls {
  display: none !important; }

.occassion_section {
  color: white;
  max-width: 100%;
  overflow: hidden;
  position: relative; }
  .occassion_section .background {
    position: absolute;
    top: 50%;
    min-height: 60vh;
    transform: translateY(-50%);
    background-image: linear-gradient(to bottom left, rgba(165, 85, 191, 0.5), rgba(150, 91, 241, 0.8)), url("./images/bg_occassion.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 1; }
    @media only screen and (max-width: 900px) {
      .occassion_section .background {
        min-height: 70vh; } }
  .occassion_section .text_wrapper {
    padding: 4rem 8rem;
    width: 50%; }
    @media only screen and (max-width: 900px) {
      .occassion_section .text_wrapper {
        width: 100%;
        padding: 1rem 2rem; } }
  .occassion_section .cover {
    position: absolute;
    background: linear-gradient(to right, #3c0d14 3px, rgba(255, 255, 255, 0.5) 5px, rgba(255, 255, 255, 0.25) 7px, rgba(255, 255, 255, 0.25) 10px, transparent 12px, transparent 16px, rgba(255, 255, 255, 0.25) 17px, transparent 22px), url("./images/book5.jpg");
    box-shadow: 0 0 5px -1px black, inset -1px 1px 2px rgba(255, 255, 255, 0.5);
    margin: auto;
    border-radius: 5px;
    width: 21.88125rem;
    height: 33.75rem;
    right: 8rem;
    top: 50%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: all .5s ease; }
    @media only screen and (max-width: 900px) {
      .occassion_section .cover {
        width: 198px;
        height: 306px;
        top: 60%; } }
    .occassion_section .cover.cover1 {
      z-index: 79;
      transform: translateY(-50%) rotate(12.5deg); }
      .occassion_section .cover.cover1:hover {
        transform: scale(1.02) translateY(-50%) rotate(12.5deg); }
      @media only screen and (max-width: 900px) {
        .occassion_section .cover.cover1 {
          right: 10%; } }
    .occassion_section .cover.cover2 {
      background: linear-gradient(to right, #3c0d14 3px, rgba(255, 255, 255, 0.5) 5px, rgba(255, 255, 255, 0.25) 7px, rgba(255, 255, 255, 0.25) 10px, transparent 12px, transparent 16px, rgba(255, 255, 255, 0.25) 17px, transparent 22px), url("./images/book4.jpg");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      right: 25rem;
      transform: translateY(-50%) rotate(-12.5deg);
      z-index: 69; }
      .occassion_section .cover.cover2:hover {
        transform: scale(1.02) translateY(-50%) rotate(-12.5deg); }
      @media only screen and (max-width: 900px) {
        .occassion_section .cover.cover2 {
          right: 42.5%; } }
    .occassion_section .cover:hover {
      z-index: 99;
      box-shadow: 5px 5px 20px #666, inset -1px 1px 2px rgba(255, 255, 255, 0.5); }

.shipping_screen {
  height: 100vh;
  position: relative; }
  .shipping_screen .address_container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%; }

.navbar a {
  text-decoration: none;
  text-transform: capitalize;
  font-size: 16px; }

.navbar.navbar-light .nav-link {
  color: black; }
  .navbar.navbar-light .nav-link:focus, .navbar.navbar-light .nav-link:active {
    color: black; }
  .navbar.navbar-light .nav-link:active {
    transform: scale(0.99); }
  .navbar.navbar-light .nav-link .bttn {
    padding: 0.5rem 1rem;
    background-color: #F01887;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    @media only screen and (max-width: 900px) {
      .navbar.navbar-light .nav-link .bttn {
        margin-bottom: .5rem;
        width: auto; } }

.navbar .me-auto .nav-link {
  padding-left: 30px;
  padding-right: 30px; }
  @media only screen and (max-width: 900px) {
    .navbar .me-auto .nav-link {
      padding-left: unset;
      padding-right: unset; } }

@media only screen and (max-width: 900px) {
  .navbar .navbar-nav {
    padding-left: 1rem; } }

.navbar .navbar-nav .nav-link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #a555bf;
  font-family: 'Source Sans Pro', sans-serif; }
  @media only screen and (max-width: 1400px) {
    .navbar .navbar-nav .nav-link {
      justify-content: start; } }
  .navbar .navbar-nav .nav-link:hover {
    color: var(--color-grey-dark-2); }
  .navbar .navbar-nav .nav-link.selected {
    color: #f01887;
    border-bottom: 3px solid #f01887; }
    @media only screen and (max-width: 900px) {
      .navbar .navbar-nav .nav-link.selected {
        border: none;
        color: #F01887;
        font-size: 20px; } }

.navbar-expand-lg .navbar-nav .dropdown-menu.show {
  left: unset; }

.nav__logo--img {
  height: 50px; }

.nav__text {
  z-index: 100; }

.bton {
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.6375rem 1.5rem;
  border-radius: 6.25rem;
  transition: all 0.2s;
  position: absolute;
  border: none;
  cursor: pointer;
  animation: moveIn 1s ease-out .75s, pulse 1s infinite 1.5s;
  animation-fill-mode: backwards;
  top: 90%;
  display: block;
  background-color: #F01887;
  color: white;
  font-weight: 700;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.11), 0 2px 4px -1px rgba(69, 47, 47, 0.7);
  font-size: 16px;
  animation: pulse 1s infinite; }
  .bton:hover {
    background-color: #f06595; }
  @media only screen and (max-width: 900px) {
    .bton {
      top: unset;
      bottom: 15%;
      width: 80%;
      left: 50%;
      transform: translateX(-50%); } }

.avatar {
  height: 25rem; }

.img_wrapper, .instruction_step {
  justify-content: center; }

.instruction_img {
  width: 250px;
  height: 250px; }

.instruction_row {
  display: flex;
  justify-content: space-between;
  width: 80%;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 0 auto; }
  .instruction_row .shadow {
    background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
    border-radius: 1rem;
    padding: 1rem 3rem;
    min-width: 20rem; }
    .instruction_row .shadow .img_wrapper {
      border: 1px solid #f01887;
      border-radius: 1rem; }
  @media only screen and (max-width: 900px) {
    .instruction_row {
      justify-content: space-around;
      flex-direction: column;
      align-items: center; } }
  @media only screen and (max-width: 900px) {
    .instruction_row .shadow {
      margin-top: 50px;
      padding: 20px 30px; } }

.instruction_step {
  bottom: 0;
  left: 50%;
  -webkit-transform: translateY(50%) translateX(-50%);
  transform: translateY(50%) translateX(-50%);
  width: 50px;
  height: 50px;
  background-color: #a555bf;
  display: flex;
  align-items: center;
  font-size: 25px;
  border-radius: 50%;
  color: #fff;
  font-weight: 300; }

.avatar_default {
  min-height: 50vh;
  max-height: 50vh;
  width: auto;
  margin: auto;
  padding: 2rem 0; }

.product_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 19.8rem;
  border-radius: 0.625rem;
  color: var(--color-grey-dark-2); }
  @media only screen and (max-width: 900px) {
    .product_card {
      max-width: 100%; } }
  .product_card .link {
    width: 100%;
    font-size: 16px; }
  .product_card .image_wrapper {
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: .25rem;
    align-items: center; }
  .product_card .image {
    width: 250px;
    height: 300px; }
  .product_card .description {
    width: 100%;
    font-weight: 300;
    font-size: 16px;
    text-align: justify;
    padding: .75rem 0;
    line-height: 1;
    color: black; }
  .product_card .price {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0rem 0;
    font-weight: 500;
    color: var(--color-grey-dark-1);
    font-size: 16px; }
    .product_card .price .amount {
      font-weight: 500; }
  .product_card .name {
    width: 100%;
    display: flex;
    padding: .25rem 0;
    text-decoration: none;
    color: var(--color-grey-dark-1);
    font-weight: 500;
    color: #F01887;
    text-transform: capitalize;
    transition: all .3s ease-out;
    font-size: 16px; }
    .product_card .name .arrow {
      display: none; }
    .product_card .name:hover {
      color: #F01887;
      text-decoration: underline;
      margin-left: -3px; }
      .product_card .name:hover .arrow {
        display: inline; }
  .product_card .bttn {
    margin-top: 0.75rem;
    padding: .35rem 1.375rem;
    border: none;
    width: 100%;
    border: 2px solid #F01887;
    background: #fff;
    border-radius: 10px;
    color: #F01887;
    font-weight: 500;
    border-radius: 20rem;
    transition: all .1s ease-out;
    font-size: 16px;
    position: relative;
    z-index: 1;
    transition: all 0.5s cubic-bezier(0.5, 0.1, 0.5, 1);
    overflow: hidden; }
    .product_card .bttn::before {
      position: absolute;
      content: '';
      background: linear-gradient(to right, #bc4e9c, #f80759);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(0px);
      -webkit-backdrop-filter: blur(0px);
      left: 0;
      top: 0;
      width: 0;
      height: 105%;
      border-radius: 20rem;
      z-index: -1;
      margin-left: 50%;
      transition: all 0.5s cubic-bezier(0.5, 0.1, 0.5, 1); }
    .product_card .bttn:hover {
      color: var(--color-grey-light-1);
      border-color: white; }
      .product_card .bttn:hover::before {
        width: 105%;
        margin-left: 0;
        border: 2px solid #F01887; }
    .product_card .bttn:active {
      background: #ee057e;
      color: var(--color-grey-light-1);
      transform: scale(0.98); }
  .product_card .book-container {
    display: flex;
    align-items: center;
    justify-content: center;
    perspective: 37.5rem; }

@keyframes initAnimation {
  0% {
    transform: rotateY(0deg); }
  100% {
    transform: rotateY(-30deg); } }
  .product_card .book {
    width: 9.375rem;
    height: 14.5rem;
    position: relative;
    transform-style: preserve-3d;
    transform: rotateY(-30deg);
    transition: 1s ease;
    animation: 1s ease 0s 1 initAnimation; }
  .product_card .book:hover {
    transform: rotateY(0deg); }
  .product_card .book > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    background-color: red;
    width: 9.375rem;
    height: 14.5rem;
    transform: translateZ(25px);
    background-color: #01060f;
    border-radius: 0 5px 5px 0;
    box-shadow: 2.5px 2.5px 2.5px #666; }
  .product_card .book::before {
    position: absolute;
    content: ' ';
    background-color: blue;
    left: 0;
    top: 0.1875rem;
    width: 3rem;
    height: 14.125rem;
    transform: translateX(122px) rotateY(90deg);
    background: linear-gradient(90deg, #fff 0%, #f9f9f9 5%, #fff 10%, #f9f9f9 15%, #fff 20%, #f9f9f9 25%, #fff 30%, #f9f9f9 35%, #fff 40%, #f9f9f9 45%, #fff 50%, #f9f9f9 55%, #fff 60%, #f9f9f9 65%, #fff 70%, #f9f9f9 75%, #fff 80%, #f9f9f9 85%, #fff 90%, #f9f9f9 95%, #fff 100%); }
  .product_card .book::after {
    position: absolute;
    top: 0;
    left: 0;
    content: ' ';
    width: 9.375rem;
    height: 14.5rem;
    transform: translateZ(-25px);
    background-color: #01060f;
    border-radius: 0 5px 5px 0;
    box-shadow: -5px 0 25px 5px #666; }

.bttn {
  padding: .35rem 2rem;
  border: none;
  width: 100%;
  background-color: #eb689f;
  border-radius: 10px;
  color: white; }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23eb689f'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23eb689f'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.btn.btn-primary {
  border-color: #f5007e;
  background-color: #ec2276;
  font-size: 14.4px;
  font-weight: 400; }
  .btn.btn-primary:hover {
    background-color: #ee057e;
    box-shadow: 0 0 0 0.25rem rgba(165, 85, 191, 0.25); }
  .btn.btn-primary:active {
    background-color: #ee057e;
    box-shadow: 0 0 0 0.25rem rgba(165, 85, 191, 0.25);
    transform: scale(0.99); }
  .btn.btn-primary:focus {
    background-color: #ec2276;
    box-shadow: 0 0 0 0.25rem rgba(165, 85, 191, 0.25); }
  .btn.btn-primary.male {
    background-color: #146eb4;
    border-color: #115f9c; }
    .btn.btn-primary.male:hover {
      background-color: #115f9c;
      box-shadow: 0 0 0 0.25rem rgba(20, 110, 180, 0.25); }
    .btn.btn-primary.male:active {
      background-color: #146eb4;
      box-shadow: 0 0 0 0.25rem rgba(20, 110, 180, 0.25); }
    .btn.btn-primary.male:focus {
      background-color: #146eb4;
      box-shadow: 0 0 0 0.25rem rgba(20, 110, 180, 0.25); }
  .btn.btn-primary.subscribe {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    background-color: #a555bf;
    border-color: #9631b8;
    position: fixed;
    left: 0;
    top: 50%;
    margin: 0;
    z-index: 8389389389;
    transform-origin: 0 50%;
    transform: rotate(-90deg) translate(-50%, 50%);
    box-shadow: none;
    border-radius: 0;
    text-transform: uppercase;
    font-size: larger;
    font-weight: 400; }
    .btn.btn-primary.subscribe:hover {
      background-color: #9631b8;
      box-shadow: none; }
    .btn.btn-primary.subscribe:active {
      background-color: #a555bf;
      box-shadow: none; }
    .btn.btn-primary.subscribe:focus {
      background-color: #a555bf;
      box-shadow: none; }

.form-control {
  background-color: var(--color-grey-light-1); }
  .form-control:focus {
    border-color: #a555bf;
    box-shadow: 0 0 0 0.25rem rgba(165, 85, 191, 0.25); }

.nav-item.show.dropdown .dropdown-toggle::after {
  transform: rotate(180deg); }

.navbar-expand-lg .navbar-nav .dropdown-menu {
  left: -50%; }

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: rgba(165, 85, 191, 0.25);
  color: var(--color-grey-dark-4--); }

.preview {
  padding: 1.5rem 5rem;
  width: 100%; }
  @media only screen and (max-width: 900px) {
    .preview {
      flex-direction: column;
      padding: 1.5rem 0; } }
  .preview .carousel {
    width: 66.67%; }
    @media only screen and (max-width: 900px) {
      .preview .carousel {
        width: 100%;
        margin-bottom: 2rem; } }
  .preview .carousel-indicators {
    margin-bottom: 0;
    bottom: -1.5rem; }
    .preview .carousel-indicators [data-bs-target] {
      background-color: #a555bf; }
  .preview .buttons {
    flex-grow: 1;
    align-items: center; }
    .preview .buttons h2 {
      font-weight: 300;
      color: #a555bf; }
    .preview .buttons .list_value {
      font-size: 14.4px;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end; }
      @media only screen and (max-width: 900px) {
        .preview .buttons .list_value {
          text-overflow: ellipsis;
          max-width: 50%; } }
    .preview .buttons .list_label {
      font-weight: 500;
      font-size: 14.4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-overflow: ellipsis; }

* {
  -webkit-user-select: none;
  user-select: none; }

input,
textarea,
[contenteditable] {
  -webkit-user-select: text;
  user-select: text; }

.address_save {
  flex-grow: 1; }

.address__input_wrapper .address_save {
  flex-grow: 1; }

.address__input_wrapper .address_input {
  max-width: 80%; }
  @media only screen and (max-width: 900px) {
    .address__input_wrapper .address_input {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 2rem; } }

.carousel.avatar_container {
  margin: 0 auto;
  background-image: linear-gradient(225deg, #e0e0e0, #e0e0e0);
  min-height: 55vh;
  box-shadow: #bebebe -20px 20px 60px 0px, white 20px -2px 60px 0px;
  border-radius: 20px; }

.avatar_container {
  width: 50%; }
  @media only screen and (max-width: 900px) {
    .avatar_container {
      width: 100%; } }

.avatar_two_button_wrapper {
  width: 50%; }
  @media only screen and (max-width: 900px) {
    .avatar_two_button_wrapper {
      width: 100%;
      flex-direction: column !important; } }

.avatar_selectors {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  width: 15rem;
  justify-content: space-evenly;
  margin-top: -2.5rem;
  padding-bottom: 4rem; }
  .avatar_selectors .selector_btn {
    border-radius: 50%;
    padding: 0;
    border-color: #a555bf;
    position: relative; }
    .avatar_selectors .selector_btn:not(:last-child) {
      margin-right: 2rem; }
    .avatar_selectors .selector_btn:active .avatar {
      transform: translate(-50%, -59%) scale(0.98); }
    .avatar_selectors .selector_btn.selected .avatar {
      transform: translate(-50%, -59%) scale(1.05);
      border: 5px solid #a555bf; }
      .avatar_selectors .selector_btn.selected .avatar.male {
        border-color: #0d6efd; }
  .avatar_selectors .avatar {
    border: 3px solid #a555bf;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -59%);
    width: 50px;
    height: 50px; }
    .avatar_selectors .avatar.male {
      border-color: #0d6efd; }

.modal-content {
  color: var(--color-grey-dark-4--);
  background: rgba(238, 174, 202, 0.25);
  background: radial-gradient(circle, rgba(238, 174, 202, 0.5) 0%, rgba(148, 187, 233, 0.5) 100%);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 1rem;
  padding: 0rem 1rem; }
  .modal-content .modal-header {
    border-bottom: none;
    padding-bottom: 0; }
  .modal-content .subscribe_icon {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-bottom: .5rem; }
  .modal-content h2 {
    margin-bottom: .5rem; }
  .modal-content .btn-subscribe {
    margin-top: 1rem;
    margin-bottom: .5rem; }

.avatar_edit_wrapper {
  position: relative; }
  .avatar_edit_wrapper .avatar_design {
    height: 25rem;
    width: 100%; }
  .avatar_edit_wrapper .part_selector {
    position: absolute;
    top: 20%;
    left: 58%;
    border-width: 2px;
    cursor: pointer; }
    @media only screen and (max-width: 1200px) {
      .avatar_edit_wrapper .part_selector {
        left: 60.5%; } }
    @media only screen and (max-width: 900px) {
      .avatar_edit_wrapper .part_selector {
        left: 65%; } }
    @media only screen and (max-width: 900px) {
      .avatar_edit_wrapper .part_selector {
        left: 70%;
        top: 25%; } }
    .avatar_edit_wrapper .part_selector.skin {
      top: 50%; }
    .avatar_edit_wrapper .part_selector.shoes {
      top: 85%; }
    .avatar_edit_wrapper .part_selector.hair {
      top: 15%;
      right: 58%;
      left: auto; }
      .avatar_edit_wrapper .part_selector.hair.pants {
        top: 70%; }
      .avatar_edit_wrapper .part_selector.hair.shirt {
        top: 40%; }
      .avatar_edit_wrapper .part_selector.hair .form-check-input {
        right: 0;
        left: 100%; }
      @media only screen and (max-width: 1200px) {
        .avatar_edit_wrapper .part_selector.hair {
          right: 60.5%; } }
      @media only screen and (max-width: 900px) {
        .avatar_edit_wrapper .part_selector.hair {
          right: 65%; } }
      @media only screen and (max-width: 600px) {
        .avatar_edit_wrapper .part_selector.hair {
          right: 70%;
          top: 20%; } }
      .avatar_edit_wrapper .part_selector.hair::before {
        left: 50%;
        right: unset;
        background: linear-gradient(90deg, #5b5b5b, rgba(255, 255, 255, 0));
        transform: translateY(-50%);
        margin-left: 4px; }
      .avatar_edit_wrapper .part_selector.hair.selected {
        animation: fadeInRight ease-in .5s; }
        .avatar_edit_wrapper .part_selector.hair.selected::before {
          left: 100%; }
    .avatar_edit_wrapper .part_selector .form-check-input {
      width: 1.5rem;
      height: 1.5rem;
      animation: pulseShadow 1s infinite;
      cursor: pointer; }
    .avatar_edit_wrapper .part_selector::before {
      display: inline-block;
      content: "";
      width: 2.5rem;
      height: 2px;
      background: linear-gradient(270deg, #5b5b5b, rgba(255, 255, 255, 0));
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translateY(50%); }
    .avatar_edit_wrapper .part_selector.selected {
      border-radius: 1rem;
      border: 2px solid var(--color-grey-dark-4);
      width: auto;
      height: auto;
      animation: fadeIn ease-in .5s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      padding: 0 1rem; }
      .avatar_edit_wrapper .part_selector.selected::before {
        right: 100%; }

.skinSelector_wrapper {
  display: flex;
  justify-content: center;
  align-self: center; }
  .skinSelector_wrapper .css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    transition: all .25s ease-in; }
  .skinSelector_wrapper .css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected.css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
    border: 4px solid #a555bf;
    transform: scale(1.2); }

#method_barter {
  display: none; }

.list-group-item {
  padding: .5rem 2rem; }

.pagination {
  justify-content: center; }
  .pagination .page-link {
    color: #a555bf; }
    .pagination .page-link:focus {
      border-color: #a555bf;
      box-shadow: 0 0 0 0.25rem rgba(165, 85, 191, 0.25); }
  .pagination .page-item.active .page-link {
    background-color: #a555bf;
    border-color: #a555bf; }

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #a555bf;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #a555bf transparent transparent transparent; }

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s; }

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s; }

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.loader {
  width: max-content;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.carousel-caption {
  right: unset;
  top: 50vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 35%;
  left: 0;
  height: fit-content;
  background-color: transparent;
  font-family: mySecondFont;
  padding: 0.625rem 2.5rem;
  color: black; }
  .carousel-caption h3 {
    display: inline;
    font-size: 1.5625rem;
    text-transform: uppercase;
    font-weight: 900;
    text-align: left; }
  .carousel-caption p {
    display: inline;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9375rem; }

.section__heading {
  display: block;
  text-align: center;
  padding: 2.5rem 0;
  color: var(--color-grey-dark-2);
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 0.625rem; }
  @media only screen and (max-width: 600px) {
    .section__heading {
      font-size: 30px; } }

.avatar_description {
  color: var(--color-grey-light-3);
  text-align: center;
  font-size: 20px;
  width: 70%;
  margin: 0 auto;
  font-weight: 300;
  margin-bottom: 1rem; }

.instruction_row h5 {
  font-size: larger;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  color: var(--color-grey-dark-1); }

.instruction_row h6 {
  font-size: 16px;
  text-align: justify;
  font-weight: normal;
  color: var(--color-grey-dark-1); }

.main_cover_text {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center; }
  .main_cover_text.sub_text {
    bottom: 100px; }

.text-primary {
  color: #f01887 !important; }

.register_link {
  color: #f01887; }

@keyframes pulse {
  0% {
    transform: translateX(-50%) scale(0.95); }
  70% {
    transform: translateX(-50%) scale(1); }
  100% {
    transform: translateX(-50%) scale(0.95); } }

@keyframes moveIn {
  from {
    bottom: -6.25rem;
    opacity: 0; }
  100% {
    bottom: 6.25rem;
    opacity: 1; } }

@keyframes pulseShadow {
  0% {
    transform: translateX(-50%) scale(0.95);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25); }
  70% {
    transform: translateX(-50%) scale(1);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35); }
  100% {
    transform: translateX(-50%) scale(0.95);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25); } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    margin-right: 2rem; }
  100% {
    opacity: 1;
    margin-right: 0; } }

@keyframes fadeIn {
  0% {
    opacity: 0;
    margin-left: 2rem; }
  100% {
    opacity: 1;
    margin-left: 0; } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    margin-top: 2rem; }
  100% {
    opacity: 1;
    margin-top: 0; } }

.flip_book {
  transition: opacity 0.4s 0.2s;
  color: var(--color-grey-light-4); }

.flip_page {
  width: 396px;
  height: 612px;
  background-color: #111111;
  float: left;
  margin-bottom: 0.5em;
  background: left top no-repeat;
  background-size: cover; }
  @media only screen and (max-width: 900px) {
    .flip_page {
      width: 198px;
      height: 306px; } }
  .flip_page p {
    text-align: center;
    font-size: larger;
    color: #000000;
    white-space: 'pre-line'; }

.flip_page:nth-child(even) {
  clear: both; }

.flip_book {
  perspective: 250vw;
  background-image: url("./images/flip-bg.jpg");
  padding: 1rem;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.flip_book .flip_pages {
  width: 792px;
  height: 612px;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #e3dfd8; }
  @media only screen and (max-width: 900px) {
    .flip_book .flip_pages {
      width: 396px;
      height: 306px; } }

.flip_book .flip_page {
  float: none;
  clear: none;
  margin: 0;
  position: absolute;
  top: 0;
  width: 396px;
  height: 612px;
  transform-origin: 0 0;
  transition: transform 1.4s;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  cursor: pointer;
  user-select: none;
  background-color: #f0f0f0; }
  @media only screen and (max-width: 900px) {
    .flip_book .flip_page {
      width: 198px;
      height: 306px; } }

.flip_book .flip_page:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  transition: background 0.7s;
  z-index: 2; }

.flip_book .flip_page:nth-child(odd) {
  pointer-events: all;
  transform: rotateY(0deg);
  right: 0;
  border-radius: 0 4px 4px 0;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 10%); }

.flip_book .flip_page:nth-child(odd):hover {
  transform: rotateY(-15deg); }

.flip_book .flip_page:nth-child(odd):hover:before {
  background: rgba(0, 0, 0, 0.03); }

.flip_book .flip_page:nth-child(odd):before {
  background: rgba(0, 0, 0, 0); }

.flip_book .flip_page:nth-child(even) {
  pointer-events: none;
  transform: rotateY(180deg);
  transform-origin: 100% 0;
  left: 0;
  border-radius: 4px 0 0 4px;
  border-color: black;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0) 10%); }

.flip_book .flip_page:nth-child(even):before {
  background: rgba(0, 0, 0, 0.2); }

.flip_book .flip_page.grabbing {
  transition: none; }

.flip_book .flip_page.flipped:nth-child(odd) {
  pointer-events: none;
  transform: rotateY(-180deg); }

.flip_book .flip_page.flipped:nth-child(odd):before {
  background: rgba(0, 0, 0, 0.2); }

.flip_book .flip_page.flipped:nth-child(even) {
  pointer-events: all;
  transform: rotateY(0deg); }

.flip_book .flip_page.flipped:nth-child(even):hover {
  transform: rotateY(15deg); }

.flip_book .flip_page.flipped:nth-child(even):hover:before {
  background: rgba(0, 0, 0, 0.03); }

.flip_book .flip_page.flipped:nth-child(even):before {
  background: rgba(0, 0, 0, 0); }

*,
* :before,
*:after {
  box-sizing: border-box; }

.flip_page:nth-child(odd) {
  background-position: right top; }

.flip_body {
  display: flex;
  justify-content: center; }
  @media only screen and (max-width: 900px) {
    .flip_body {
      width: 100%; } }
  .flip_body.placeorder {
    width: 75%;
    padding-top: 0; }
